<template>
  <div class="policy-topics">
    <!--面包屑区域-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }" class="breadcrumb_home"
        >首页</el-breadcrumb-item
      >
      <el-breadcrumb-item class="breadcrumb">政策专题</el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <!--    tabs标签页选项-->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(items, i) in getDataFroms2"
          :key="i"
          :label="items.name"
          :name="'' + items.id"
        >
          <!--        描述区域-->
          <div class="policy-desc flex row">
            <img :src="topicDesc.inner" alt="" />
            <div class="flex col around">
              <div class="">{{ topicDesc.name }}</div>
              <div class="">{{ topicDesc.describe }}</div>
            </div>
          </div>
          <!--        相关政策区域-->
          <div class="policy-detail">
            <div class="detail-title">{{ topicDesc.name }}相关政策</div>
            <policy-list-comp :getTopicDetail="topicDetail"></policy-list-comp>
          </div>
          <!--        分页导航区域-->
          <div class="pagination">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="topicDesc.totalcount+parseInt(topicDesc.totalcount/size)"
              prev-text="上一页"
              next-text="下一页"
              @current-change="currentChange"
              :current-page="page"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import policyListComp from "./components/policyListComp";
import { request } from "@/network";

export default {
  name: "indexPolicyTopics",
  data() {
    return {
      activeName: "0",
      tabsData: [],
      topicId: 0,
      page: 1,
      size: 9,
      topicData: [],
      getDataFroms2: [],
      topicDesc: {},
      topicDetail: [],
      routerData: [],
    };
  },
  components: {
    policyListComp,
  },
  activated() {
    this.routerData = JSON.parse(sessionStorage.getItem("policyTopicKey"));
    this.handlerMountData();
  },
  mounted() {
    this.routerData = JSON.parse(sessionStorage.getItem("policyTopicKey"));
    if (!this.routerData) {
      // this.$router.push('/index');
      this.getPolicyTopic().then(() => {
        this.handlerMountData();
      });
    } else {
      this.handlerMountData();
    }
  },
  methods: {
    handlerMountData() {
      this.activeName = "" + this.routerData[0].clickid;
      this.topicId = this.routerData[0].clickid;
      this.getDataFroms2 = this.routerData;
      this.getTopics();
    },
    async getPolicyTopic() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/getPolicyTopic",
      });
      const { code, data, message } = res;
      if (code !== 200) return this.$message.error(message);
      let clickId = data[0].id;
      let arr = [];
      data.forEach((item) => {
        let obj = {};
        obj = {
          id: item.id,
          name: item.name,
          clickid: clickId,
        };
        arr.push(obj);
      });
      window.sessionStorage.setItem("policyTopicKey", JSON.stringify(arr));
      this.routerData = arr;
    },
    async getTopics() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/getPolicyTopic/policyTopicList",
        params: {
          id: this.topicId,
          page: this.page,
          size: this.size,
        },
      });
      if (res.code !== 200) return this.$message.error(res.message);
      this.tabsData = res.data;
      this.topicDesc = this.tabsData[0];
      this.topicDetail = this.tabsData.slice(1);
    },
    async handleClick(tab, event) {
      this.topicId = tab.name;
      this.routerData[0].clickid = this.topicId;
      window.sessionStorage.setItem(
        "policyTopicKey",
        JSON.stringify(this.routerData)
      );
      this.page = 1;
      this.getTopics();
    },
    currentChange(val) {
      this.page = val;
      this.getTopics();
    },
  },
  destroyed() {
    window.sessionStorage.removeItem("policyTopicKey");
  },
};
</script>

<style lang="less" scoped>
.policy-topics {
  padding: 45px 0px 60px;
  background-color: #fff;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.policy-desc {
  width: 100%;
  height: 208px;
  background-color: #f2f7ff;
  > img:nth-child(1) {
    width: 317px;
    height: 208px;
  }
  > div:nth-child(2) {
    width: 100%;
    height: 208px;
    div:nth-child(1) {
      width: 90%;
      height: 88px;
      font-size: 24px;
      font-weight: bold;
      line-height: 128px;
    }
    div:nth-child(2) {
      width: 90%;
      height: 120px;
      font-size: 16px;
      color: #535558;
      line-height: 25px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}
.policy-detail {
  margin-top: 40px;
}
.policy-detail .detail-title {
  padding-bottom: 19px;
  margin-bottom: 20px;
  font-size: 22px;
  color: #015293;
  border-bottom: 2px solid #015293;
}
.pagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
}
/deep/ .el-tabs__item {
  font-size: 18px;
  color: #000000;
}
/deep/.is-active {
  font-size: 18px;
  font-weight: bold;
  color: #156ED0;
}
.breadcrumb_home {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  opacity: 0.6;
}
.breadcrumb {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
}
</style>
